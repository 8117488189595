.btn-wordtune {
	display: block;
	padding: 5px;
	cursor: pointer;
}

.btn-wordtune img {
	display: block;
	max-width: 25px;
}
