.source-content-container {
    position: fixed;
    padding: 20px;
    border-top: 1px solid #DDD;
    border-right: 1px solid #DDD;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    top: 80px;
    left: 0;
    bottom: 59px;
    width: 30vw;
    transform: translateX(-30%);
    opacity: 0;
    filter: alpha(opacity = 0);
    z-index: 100;
    transition: all .3s ease-in-out;
    display: none;
}

.source-content-container.active {
    transform: translateX(0%);
    opacity: 1;
    filter: alpha(opacity = 100);
    display: block;
}