.timer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.timer-container button {
    margin-right: 15px;
}

.timer-container .timer {
    font-size: 20px;
    width: 100px;
    text-align: left;
}