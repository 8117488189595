.rewrite-element {
	margin-bottom: 0;
	transition: all .3s ease-out;
	position: relative;
}

.rewrite-element.new {
	filter: brightness(90%);
}

.rewrite-element .original-text div {
	display: inline;
	position: relative;
}

.rewrite-element .original-text .thesaurus span {
	text-decoration: underline;
}

.rewrite-element.paragraph,
.rewrite-element.other {
	background-color: var(--group-alternate-background-color);
	color: #6d6d6d;
}

.rewrite-element.heading {
	background-color: var(--group-background-color);
	color: #4a4a4a;
}

.rewrite-element:last-child {
	margin-bottom: 0;
}

.rewrite-element-container {
	position: relative;
	border: 1px solid transparent;
	padding: calc(var(--default-margin) / 2) calc(var(--default-margin) / 3);
	margin-left: calc(var(--default-margin) / 3);
	margin-right: calc(var(--default-margin) / 3);
}

.rewrite-element-container:hover {
	border: 1px dashed #9b9b9b;
	border-radius: 10px;
}

.element-editor-container {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.bullet-instructions {
	display: block;
	text-align: left;
	width: 100%;
	color: #4a90e2;
	font-style: italic;
	margin-bottom: 10px;
}

.element-movement {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateX(-100%) translateY(-50%);
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--actions-background-color);
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.element-movement > .btn {
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
}

.rewrite-element:hover .element-movement {
	display: flex;
}

.element-editor {
	background-color: var(--editor-background);
	flex: 1;
}

.element-editor .mce-content-body {
	padding: 5px;
	border: 1px solid #c3c1c3;
}

.element-editor .mce-content-body p,
.element-editor .mce-content-body h2 {
	margin: 0;
}

.element-editor .mce-edit-focus:focus {
	background-color: var(--editor-focus-background);
	border: 0;
	outline: none;
}

.element-actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
	padding-left: var(--default-margin);
}

.element-actions > div {
	line-height: 35px;
	padding-left: 10px;
	padding-right: 10px;
}

.element-actions > div > span {
	color: var(--character-count-color);
}

.element-actions > * {
	background-color: var(--actions-background-color);
	margin-right: 5px;
	font-size: 14px;
}

.element-actions > *:last-child {
	margin-right: 0;
}

.rewrite-element .element-top-actions {
	position: absolute;
	top: 10px;
	right: -10px;
	transform: translateX(100%);
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.rewrite-element .element-top-actions > * {
	margin-left: 10px;
}
