.thesaurus-dialog {
    display: block !important;
    position: absolute !important;
    top: 100%;
    left: 0;
    width: 30vw;
    padding: 30px;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    z-index: 100;
    box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.thesaurus-dialog h4 {
    color: #357bd2;
    font-style: italic;
}

.thesaurus-dialog .suggestions-container {
    margin-left: 20px;
}

.thesaurus-dialog div {
    display: block !important;
}

.thesaurus-dialog .spinner-border {
    margin: 0 auto;
}

.thesaurus-dialog .source-word {
    display: inline-block !important;
}

.thesaurus-dialog .word-suggestion {
    display: inline-block;
    padding: 8px 20px;
    color: #696a70;
    border-radius: 5px;
}

.thesaurus-dialog .word-suggestion:hover {
    cursor: pointer;
    background-color: #f2f2f2;
}

.word.thesaurus span {
    text-decoration-color: #cccccc !important;
}