.parser-container > .tox-tinymce {
  height: calc(100vh - 440px) !important;
}

.parser-container .actions {
  margin-top: var(--default-margin);
}

.parser-container .instructions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.parser-container .instructions-container a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.parser-container .instructions-container a i {
  margin-right: 10px;
  color: #d0021b;
  font-size: 24px;
}

input[type=number].word-count {
  width: 30%;
}