.thesaurus-panel {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	transition: all .3s ease-in-out;
}
.thesaurus-panel .thesaurus-panel-toggle {
	flex: 1;
	background-color: blue;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	cursor: pointer;
}

.thesaurus-panel .thesaurus-panel-toggle span {
	display: block;
	transform: rotate(-90deg);
	font-size: 24px;
}

.thesaurus-panel.expanded {
	border-left: 1px solid #ddd;
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.thesaurus-panel-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
}

.thesaurus-panel-content .thesaurus-actions-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #ddd;
}

.thesaurus-panel-content .thesaurus-actions-container .btn-close {
	border-right: 1px solid #ddd;
}

.thesaurus-panel-content .thesaurus-actions-container .btn-open-thesaurus {
	border-left: 1px solid #ddd;
}

.thesaurus-panel-content .thesaurus-frame-container {
	flex: 1;
}
