:root {
	--default-margin: 30px;
	--editor-background: white;
	--editor-focus-background: #fffbcb;
	--group-background-color: #edf5ff;
	--group-alternate-background-color: #efffe5;
	--actions-background-color: #ddd;
	--character-count-color: #d44141;
	--thesaurus-panel-width: 400px;
}

/* hack to remove cloud error message!!! */
.tox-notifications-container {
	display: none !important;
}

.clipboard {
	visibility: hidden;
}

.rewrite-container {
	position: relative;
}

.rewrite-container .rewrite-instructions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	padding-right: var(--default-margin);
}

.rewrite-container .rewrite-instructions p {
	flex: .75;
}

.rewrite-container .rewrite-instructions .rewrite-instructions-video {
	flex: .25;
	text-align: right;
	font-size: 14px;
}

.rewrite-container .rewrite-instructions .rewrite-instructions-video > span {
	display: inline-block;
}

.rewrite-container .rewrite-instructions .rewrite-instructions-video a {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.rewrite-container .rewrite-instructions .rewrite-instructions-video a i {
	margin-right: 10px;
	color: #d0021b;
	font-size: 24px;
}

.rewrite-container .rewrite-instructions .rewrite-instructions-video a:hover {
	text-decoration: none;
}

.article-actions-container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	padding: 10px var(--default-margin);
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #f2f2f2;
	border-top: 1px solid #ddd;
}

.article-link {
	margin: 0;
}

.article-link .btn {
	margin-left: 20px;
}

.elements-group.paragraph,
.elements-group.other {
	background-color: var(--group-alternate-background-color);
	color: #6d6d6d;
}

.elements-group.heading {
	background-color: var(--group-background-color);
	color: #4a4a4a;
}

.text-switcher {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.text-switcher > div {
	padding-right: var(--default-margin);
}

.word-counter-editor {
	display: none;
}

input[type="checkbox"].sc-checkbox-switch {
	display: none;
	position: absolute;
	margin: 5px 0 0 16px;
}

input[type="checkbox"].sc-checkbox-switch + label {
	position: relative;
	padding: 0 0 0 50px;
	line-height: 2em;
	margin: 0;
}

input[type="checkbox"].sc-checkbox-switch + label:before {
	content: "";
	position: absolute;
	display: block;
	left: 0;
	top: 5px;
	width: 40px; /* x*5 */
	height: 24px; /* x*3 */
	border-radius: 16px; /* x*2 */
	background: #fff;
	border: 1px solid #d9d9d9;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

input[type="checkbox"].sc-checkbox-switch + label:after {
	content: "";
	position: absolute;
	display: block;
	left: 0px;
	top: 5px;
	width: 24px; /* x*3 */
	height: 24px; /* x*3 */
	border-radius: 16px; /* x*2 */
	background: #fff;
	border: 1px solid #d9d9d9;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

input[type="checkbox"].sc-checkbox-switch + label:hover:after {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type="checkbox"].sc-checkbox-switch:checked + label:after {
	margin-left: 16px;
}

input[type="checkbox"].sc-checkbox-switch:checked + label:before {
	background: #55d069;
}
