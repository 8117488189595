.thesaurus-frame-container {
	position: relative;
	width: var(--thesaurus-panel-width);
}

.thesaurus-frame-container iframe {
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
