.words-counter .red {
	color: #df695c;
}

.words-counter .green {
	color: #55910b;
}

.rewriter-page-offseter {
	min-height: 80vh;
}

.editor-wrapper {
	position: relative;
}

.thesaurus-panel-container {
	position: fixed;
	top: 80px;
	right: 0;
	height: calc(100vh - 139px);
}

@media screen and (max-width: 1300px) {
	.article-link span {
		display: none;
	}
}
