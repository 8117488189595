.complete-container > .tox-tinymce {
  height: calc(100vh - 240px) !important;
}

.complete-container .top-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: var(--default-margin) 0;
}

.complete-container .top-stats-container div {
  font-size: 20px;
  text-align: center;
}

.complete-container .top-stats-container .green {
  color: #417505;
}

.content-complete {
  font-size: 14px;
  text-align: right;
}

.content-complete .rewrite-instructions-video {
  margin-left: auto;
  margin-right: 0;
  display: inline-block;
}

.content-complete .rewrite-instructions-video a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.content-complete .rewrite-instructions-video a i {
  margin-right: 10px;
  color: #d0021b;
  font-size: 24px;
}

.content-complete .rewrite-instructions-video a:hover {
  text-decoration: none;
}